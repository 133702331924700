angular.module('MyHippoCommons.Directives').directive('mortgageModal', function () {
    return {
        restrict: 'E',
        scope: { mortgage: '<' },
        templateUrl: '/modals/mortgage/mortgage.html',
        controller: function ($scope, $mdDialog) {
            $scope.form = {};
            $scope.mortgageData = _.cloneDeep($scope.mortgage);
            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.save = function () {
                if ($scope.form.mortgage.$valid) {
                    _.assign($scope.mortgage, $scope.mortgageData);
                    $mdDialog.hide();
                }
            };
        }
    };
});
